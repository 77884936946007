<template>
  <div class="content-box sss">
    <div class="header" :class="{ active: headerScroll }">
      <div class="logo">
        <img src="../../assets/home/logo.png" alt="logo" />
      </div>
    </div>
    <div class="content">
      <div class="bannerBox">
        <img src="../../assets/home/banner.png" />
      </div>
      <!-- 产品介绍 -->
      <div class="introduceBOX wow animate__animated animate__fadeInUp">
        <div class="titleName">
          <div class="titSign">
            <img src="../../assets/home/titSign.png" width="100%" />
          </div>
          <div class="name">产品介绍</div>
          <div class="titSign">
            <img src="../../assets/home/titSign.png" width="100%" />
          </div>
        </div>
        <div class="introduce">
          <div>
            快易刷一款为商户和服务商开发的APP，根据使用者的不同身份触达不同功能，
          </div>
          <div>
            为您提供安全、使捷、智能化服务，商户管理因此而变得更加高效。
          </div>
          <div>合作伙伴可通过App快速入网。</div>
        </div>
        <div class="userBox">
          <div class="serBox">
            <div class="imgBox">
              <div class="serImg">
                <img src="../../assets/home/Service.png" width="100%" />
              </div>
            </div>
            <div class="serText">
              <div class="li"></div>
              <div class="ss">服务商: 通过APP进行业务拓展。</div>
            </div>
          </div>
          <div class="serBox right">
            <div class="imgBox mer">
              <div class="merImg">
                <img src="../../assets/home/Merchant.png" width="100%" />
              </div>
            </div>

            <div class="serText">
              <div class="li"></div>
              <div class="ss">
                <div>终端， 为商户提供POS收款服务。</div>
                <div>商户: 主要满足商户随时随地进行收款，配合POS</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品功能 -->
      <div class="productBox wow animate__animated animate__fadeInUp">
        <div class="titleName">
          <div class="titSign">
            <img src="../../assets/home/titSign.png" width="100%" />
          </div>
          <div class="name">产品功能</div>
          <div class="titSign">
            <img src="../../assets/home/titSign.png" width="100%" />
          </div>
        </div>
        <div class="content">
          <!-- 服务商功能 -->
          <div class="productItem wow animate__animated animate__fadeInUp">
            <div class="name">服务商功能</div>
            <div class="itemBox">
              <!-- <div v-for="(item, index) in serviceList" ::key="index" class="item">
                <div class="itemIcon">
                  <img :src="item.icon" width="100%" />
                </div>
                <div class="text">{{ item.name }}</div>
              </div> -->
              <div class="row">
                <div class="item">
                  <div class="itemIcon">
                    <img src="../../assets/home/int.png" width="100%" />
                  </div>
                  <div class="text">商户入网</div>
                </div>
                <div class="item">
                  <div class="itemIcon">
                    <img src="../../assets/home/search.png" width="100%" />
                  </div>
                  <div class="text">商户查询</div>
                </div>
                <div class="item">
                  <div class="itemIcon">
                    <img src="../../assets/home/terminal.png" width="100%" />
                  </div>
                  <div class="text">终端管理</div>
                </div>
              </div>
              <div class="row mt70">
                <div class="item">
                  <div class="itemIcon">
                    <img src="../../assets/home/bind.png" width="100%" />
                  </div>
                  <div class="text">终端绑定</div>
                </div>
                <div class="item">
                  <div class="itemIcon">
                    <img src="../../assets/home/share.png" width="100%" />
                  </div>
                  <div class="text">分享</div>
                </div>
                <div class="item"></div>
              </div>
            </div>
          </div>
          <!-- 商户主要功能 -->
          <div
            class="productItem merBox wow animate__animated animate__fadeInUp"
          >
            <div class="name">商户主要功能</div>
            <div class="itemBox">
              <!-- <div v-for="(item, index) in merList" ::key="index" class="item">
                <div class="itemIcon">
                  <img :src="item.icon" width="100%" />
                </div>
                <div class="text">{{ item.name }}</div>
              </div> -->
              <div class="row">
                <div class="item">
                  <div class="itemIcon">
                    <img src="../../assets/home/quick.png" width="100%" />
                  </div>
                  <div class="text">快捷交易</div>
                </div>
                <div class="item">
                  <div class="itemIcon">
                    <img src="../../assets/home/transaction.png" width="100%" />
                  </div>
                  <div class="text">交易结算查询</div>
                </div>
                <div class="item">
                  <div class="itemIcon">
                    <img src="../../assets/home/bind.png" width="100%" />
                  </div>
                  <div class="text">终端绑定</div>
                </div>
              </div>
              <div class="row mt70">
                <div class="item">
                  <div class="itemIcon">
                    <img
                      src="../../assets/home/authentication.png"
                      width="100%"
                    />
                  </div>
                  <div class="text">磁条大额认证</div>
                </div>
                <div class="item">
                  <div class="itemIcon">
                    <img src="../../assets/home/change.png" width="100%" />
                  </div>
                  <div class="text">变更结算</div>
                </div>
                <div class="item"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 优势 -->
      <div class="advantageBox wow animate__animated animate__fadeInUp">
        <div class="titleName">
          <div class="titSign">
            <img src="../../assets/home/titSign.png" width="100%" />
          </div>
          <div class="name">产品优势</div>
          <div class="titSign">
            <img src="../../assets/home/titSign.png" width="100%" />
          </div>
        </div>
        <div class="content">
          <div class="left">
            <div class="itemImg">
              <img src="../../assets/home/POS.png" width="100%" />
            </div>
            <div class="textBox">
              <div class="title">移动POS</div>
              <div class="desc">
                外观小巧，方便携带，支持全部银联卡，支付宝，微信，云闪付。
              </div>
            </div>
          </div>
          <div class="right">
            <div class="itemImg">
              <img src="../../assets/home/security.png" width="100%" />
            </div>
            <div class="textBox">
              <div class="title">安全认证</div>
              <div class="desc">通过银联，银行卡POS检测中心双重认证</div>
            </div>
          </div>
        </div>
        <!-- <div class="itemBox">
          <div class="itemImg">
            <img src="../../assets/home/POS.png" />
          </div>

          <div class="itemImg">
            <img src="../../assets/home/security.png" />
          </div>
        </div> -->
      </div>
      <!-- 版权 -->
      <div class="footer">
        <div class="info">
          <div>公司：深圳市中易刷科技有限公司</div>
          <div>
            <span
              >地址：深圳市罗湖区黄贝街道新秀社区沿河北路1002号瑞思大厦33层3302号</span
            >
          </div>
          <div>客服电话：400-000-2622</div>
        </div>
      </div>
      <div class="line"></div>
      <div class="copyright">
        <div>Copyright@2023 快易刷 版权所有</div>
        <div @click="go" class="info-num">粤ICP备2023103369号-1</div>
      </div>
    </div>
    <div class="backTop" v-if="btnFlag" @click="backTop">
      <img src="../../assets/home/top.png" />
    </div>
  </div>
</template>

<script>
import "animate.css";
import { WOW } from "wowjs";
export default {
  data() {
    return {
      headerScroll: false, //透明判断
      serviceList: [
        {
          name: "商户入网",
          icon: require("../../assets/home/int.png"),
        },
        {
          name: "商户查询",
          icon: require("../../assets/home/search.png"),
        },
        {
          name: "终端管理",
          icon: require("../../assets/home/terminal.png"),
        },
        {
          name: "终端绑定",
          icon: require("../../assets/home/bind.png"),
        },
        {
          name: "分享",
          icon: require("../../assets/home/share.png"),
        },
      ],
      merList: [
        {
          name: "快捷交易",
          icon: require("../../assets/home/quick.png"),
        },
        {
          name: "交易结算查询",
          icon: require("../../assets/home/transaction.png"),
        },
        {
          name: "终端绑定",
          icon: require("../../assets/home/bind.png"),
        },
        {
          name: "磁条大额认证",
          icon: require("../../assets/home/authentication.png"),
        },
        {
          name: "变更结算",
          icon: require("../../assets/home/change.png"),
        },
      ],
      advantageList: [
        {
          name: "移动POS",
          url: require("../../assets/home/POS.png"),
          dec: "外观小巧，方便携带，支持全部银联卡，支付宝，微信，云闪付。",
        },
        {
          name: "安全认证",
          url: require("../../assets/home/security.png"),
          dec: "通过银联，银行卡POS检测中心双重认证",
        },
      ],
      btnFlag: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    this.$nextTick(() => {
      //页面渲染完，在执行
      let wow = new WOW({
        live: false,
      });
      wow.init();
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },

  methods: {
    go() {
      // location.href = "https://beian.miit.gov.cn/#/Integrated/index";
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
    // 点击回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const upRoll = setInterval(() => {
        const top = document.documentElement.scrollTop; // 每次获取页面被卷去的部分
        const speed = Math.ceil(top / 10); // 每次滚动多少 （步长值）
        if (document.documentElement.scrollTop > 0) {
          document.documentElement.scrollTop -= speed; // 不在顶部 每次滚动到的位置
        } else {
          clearInterval(upRoll); // 回到顶部清除定时器
        }
      }, 10);
    },

    // 为了计算距离顶部的高度，当高度大于600显示回顶部图标，小于600则隐藏
    scrollToTop() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      that.scrollTop > 92
        ? (that.headerScroll = true)
        : (that.headerScroll = false);
      if (that.scrollTop > 600) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.content-box {
  width: 100%;
  background: #ffffff;
  height: 100%;
  min-width: 1200px;
  .header {
    position: fixed;
    left: 0;
    top: 0;
    height: 92px;
    background: #fffdf9;
    width: 100%;
    z-index: 9999;
    &.active {
      background: #fff;
    }
    .logo {
      margin-top: 18px;
      margin-left: 160px;
      width: 163px;
      height: 56px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .content {
    margin-top: 92px;
    position: relative;
    .bannerBox {
      width: 100%;
      height: 800px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .introduceBOX {
      height: 860px;
      padding: 0px 160px;
      box-sizing: border-box;
      background: #fbfbfb;
      position: relative;
      .titleName {
        text-align: center;
        display: flex;
        padding-top: 58px;
        justify-content: center;
        .titSign {
          width: 50px;
          height: 36px;
          margin: auto 0;
        }
        .name {
          font-size: 42px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          margin: 0px 20px;
        }
      }
      .introduce {
        text-align: center;
        font-size: 26px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        margin-top: 42px;
        line-height: 34px;
        letter-spacing: 1px;
      }
      .userBox {
        margin-top: 110px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        .serBox {
          flex: 1;
        }
        .right {
          justify-content: center;
        }
        .serImg {
          width: 400px;
          height: 360px;
          margin-bottom: 22px;
        }
        .imgBox {
          display: flex;
          justify-content: center;
          &:hover {
            transform: translateY(-6px);
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            box-shadow: 0 4px 8px 0 transparent;
            cursor: pointer;
          }
        }
        .merImg {
          text-align: center;
          width: 400px;
          height: 360px;
          margin-bottom: 22px;
        }
        .mer {
          justify-content: center;
          display: flex;
          flex-wrap: wrap;
        }
        .serText {
          font-size: 22px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          color: #333333;
          line-height: 28px;
          letter-spacing: 1px;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          .li {
            width: 29px;
            height: 29px;
            background: linear-gradient(180deg, #ff702e 3%, #fdaa19 100%);
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
          }
          .ss {
            text-align: left;
            margin-left: 35px;
          }
        }
      }
    }
    // 产品功能
    .productBox {
      position: relative;
      .titleName {
        text-align: center;
        display: flex;
        padding-top: 58px;
        justify-content: center;
        .titSign {
          width: 50px;
          height: 36px;
          margin: auto 0;
        }
        .name {
          font-size: 42px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          margin: 0px 20px;
        }
      }
      .content {
        display: flex;
        padding: 0 160px 180px;
        justify-content: space-between;
        margin-top: 132px;
        .productItem {
          flex: 1;
          &.merBox {
            margin-left: 140px;
          }
          .name {
            color: #333333;
            font-size: 32px;
            font-weight: 700;
            text-align: center;
          }
          .itemBox {
            border: 1px solid #edecec;
            border-radius: 16px;
            margin-top: 23px;
            padding: 40px 80px;
            .mt70 {
              margin-top: 70px;
            }

            .row {
              display: flex;
              justify-content: space-between;
              .item {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-right: 60px;
                &:nth-child(3) {
                  margin-right: 0;
                }
                .itemIcon {
                  width: 100px;
                  height: 100px;
                }
                .text {
                  margin-top: 24px;
                  font-size: 24px;
                  color: #333333;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
    //产品优势
    .advantageBox {
      background: #fbfbfb;
      padding: 0 160px 250px;
      .titleName {
        text-align: center;
        display: flex;
        padding-top: 58px;
        justify-content: center;
        .titSign {
          width: 50px;
          height: 36px;
          margin: auto 0;
        }
        .name {
          font-size: 42px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          margin: 0px 20px;
        }
      }
      .content {
        display: flex;
        justify-content: space-between;
        .left,
        .right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .itemImg {
            width: 120px;
            height: 120px;
            z-index: 99;
          }
          .textBox {
            border-radius: 16px;
            background: #ffffff;
            margin-top: -60px;
            width: 100%;
            font-weight: 700;
            padding: 110px 50px 40px;
            box-sizing: border-box;
            .title {
              color: #3e3a37;
              font-size: 30px;
            }
            .desc {
              color: #3e3a37;
              font-size: 24px;
              margin-top: 22px;
            }
          }
        }
        .left {
          margin-right: 140px;
        }
      }
    }
    .footer {
      padding: 0px 160px;
      box-sizing: border-box;
      background: #ffffff;
      .info {
        padding: 40px 0;
        font-size: 20px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 33px;
        .info-tel {
          margin-left: 100px;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #e0e0e0;
    }
    .copyright {
      box-sizing: border-box;
      padding: 22px 0;
      background: #ffffff;
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #333333;
      line-height: 33px;
      .info-num {
        cursor: pointer;
      }
    }
  }
  .backTop {
    width: 75px;
    height: 75px;
    position: fixed;
    right: 32px;
    bottom: 130px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    &:hover {
      animation: shake 800ms ease-in-out;
    }
  }
  @keyframes shake {
    /* 水平抖动，核心代码 */
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(+2px, 0, 0);
    }
    30%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(+4px, 0, 0);
    }
    50% {
      transform: translate3d(-4px, 0, 0);
    }
  }
}
a {
  outline: none;
}
</style>
